import { useTranslation } from '@pancakeswap/localization'
import { WalletConnectorNotFoundError, WalletSwitchChainError } from '@pancakeswap/ui-wallets'
import replaceBrowserHistory from '@pancakeswap/utils/replaceBrowserHistory'
import { ConnectorNames } from '../config/wallet'
import { useCallback } from 'react'
import { useAppDispatch } from '../state'
import {
  ConnectorNotFoundError,
  SwitchChainError,
  SwitchChainNotSupportedError,
  useConnect,
  useDisconnect,
  useNetwork,
  useSignMessage,
} from 'wagmi'
import { clearUserStates } from '../utils/clearUserStates'
import { useActiveChainId } from './useActiveChainId'
import { useSessionChainId } from './useSessionChainId'
import { fetchMyStake, fetchMyStakeData, fetchMyStakePendingData } from 'state/pools'
interface AuthData {
  address: string
  message: string
  signature: string
  provider: string
  chain_id: number
}
const useAuth = () => {
  const dispatch = useAppDispatch()
  const { connectAsync, connectors } = useConnect()
  const { chain } = useNetwork()
  const { disconnectAsync } = useDisconnect()
  const { chainId } = useActiveChainId()
  const [, setSessionChainId] = useSessionChainId()
  const { t } = useTranslation()
  const { signMessageAsync } = useSignMessage()

  const login = useCallback(
    async (connectorID: ConnectorNames) => {
      const findConnector = connectors.find((c) => c.id === connectorID)
      try {
        const connected = await connectAsync({ connector: findConnector, chainId })
        if (!connected.chain.unsupported && connected.chain.id !== chainId) {
          replaceBrowserHistory('chainId', connected.chain.id)
          setSessionChainId(connected.chain.id)
        }

        const message = 'Hello, authenticate me!'
        const signature = await signMessageAsync({ message })

        const authData: AuthData = {
          address: connected.account,
          message,
          signature,
          provider: findConnector.name ?? 'unknown',
          chain_id: connected.chain.id,
        }

        // Kirim request ke backend
        const response = await fetch(`https://betacontract.nusabyte.com/auth`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(authData),
        })

        if (response.ok) {
          const data = await response.json()
          console.log('Authentication successful:', data)
          localStorage.setItem('authToken', data.message)
          dispatch(fetchMyStakeData())
          dispatch(fetchMyStake())
          dispatch(fetchMyStakePendingData())
        } else {
          console.error('Authentication failed:', response.statusText)
        }
        return connected
      } catch (error) {
        if (error instanceof ConnectorNotFoundError) {
          throw new WalletConnectorNotFoundError()
        }
        if (error instanceof SwitchChainNotSupportedError || error instanceof SwitchChainError) {
          throw new WalletSwitchChainError(t('Unable to switch network. Please try it on your wallet'))
        }
      }
      return undefined
    },
    [connectors, connectAsync, chainId, setSessionChainId, t],
  )

  const logout = useCallback(async () => {
    try {
      await disconnectAsync()
    } catch (error) {
      console.error(error)
    } finally {
      localStorage.removeItem('authToken')
      clearUserStates(dispatch, { chainId: chain?.id })
    }
  }, [disconnectAsync, dispatch, chain?.id])

  return { login, logout }
}

export default useAuth
