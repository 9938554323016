import { ChainId } from '@pancakeswap/sdk'
import { isStableFarm, SerializedFarmConfig } from '@pancakeswap/farms'

let logged = false

// export const getFarmConfig = async (chainId: ChainId) => {
//   const getAuthToken = () => {
//     return localStorage.getItem('authToken') || ''
//   }
//   const farmConfigApi = 'http://192.168.0.124:3666'
//   try {
//     const currentPage = window.location.pathname
//     if (currentPage == '/farms') {
//       const farmList = (await (await fetch(`${farmConfigApi}/farms`)).json()) as SerializedFarmConfig[]
//       const farmSuccess = farmList.filter((item) => item.status === 'success')
//       const modifiedFarmList = farmSuccess.map((farm, index) => ({
//         pid: index,
//         lpSymbol: farm.lpSymbol?.replace(' - ', '-') + ' LP',
//         lpAddress: farm.lpAddress || '',
//         token: farm.token
//           ? {
//               address: farm.token.address || '',
//               chainId: farm.token.chainId || 97,
//               decimals: Number(farm.token.decimals) || '',
//               symbol: farm.token.symbol || '',
//               name: (farm.token.name || '')?.replace(' Token', ''),
//               projectLink: 'https://craswap.com',
//             }
//           : {
//               address: '',
//               chainId: 97,
//               decimals: '',
//               symbol: '',
//               name: '',
//               projectLink: 'https://craswap.com',
//             },

//         quoteToken: farm.quoteToken
//           ? {
//               address: farm.quoteToken.address || '',
//               chainId: farm.quoteToken.chainId || 97,
//               decimals: Number(farm.quoteToken.decimals) || '',
//               symbol: farm.quoteToken.symbol || '',
//               name: farm.quoteToken.name || '',
//               projectLink: 'https://tether.to',
//             }
//           : {
//               address: '',
//               chainId: 97,
//               decimals: '',
//               symbol: '',
//               name: '',
//               projectLink: 'https://tether.to',
//             },
//       }))
//       return modifiedFarmList
//     } else {
//       const myFarmList = (await (
//         await fetch(`${farmConfigApi}/farms/me`, {
//           headers: {
//             'Content-Type': 'application/json',
//             Authorization: `Bearer ${getAuthToken()}`,
//           },
//         })
//       ).json()) as SerializedFarmConfig[]
//       const myFarmSuccess = myFarmList.filter((item) => item.status === 'success')
//       const modifiedFarmList = myFarmSuccess.map((farm, index) => ({
//         pid: index,
//         lpSymbol: farm.lpSymbol?.replace(' - ', '-') + ' LP',
//         lpAddress: farm.lpAddress || '',
//         token: farm.token
//           ? {
//               address: farm.token.address || '',
//               chainId: farm.token.chainId || 97,
//               decimals: Number(farm.token.decimals) || '',
//               symbol: farm.token.symbol || '',
//               name: (farm.token.name || '')?.replace(' Token', ''),
//               projectLink: 'https://craswap.com',
//             }
//           : {
//               address: '',
//               chainId: 97,
//               decimals: '',
//               symbol: '',
//               name: '',
//               projectLink: 'https://craswap.com',
//             },

//         quoteToken: farm.quoteToken
//           ? {
//               address: farm.quoteToken.address || '',
//               chainId: farm.quoteToken.chainId || 97,
//               decimals: Number(farm.quoteToken.decimals) || '',
//               symbol: farm.quoteToken.symbol || '',
//               name: farm.quoteToken.name || '',
//               projectLink: 'https://tether.to',
//             }
//           : {
//               address: '',
//               chainId: 97,
//               decimals: '',
//               symbol: '',
//               name: '',
//               projectLink: 'https://tether.to',
//             },
//       }))
//       return modifiedFarmList
//     }
//   } catch (error) {
//     if (!logged) {
//       console.error('Cannot get farm config', error, chainId)
//       logged = true
//     }
//     return []
//   }
// }

// export const getFarmPending = async (chainId: ChainId) => {
//   const getAuthToken = () => {
//     return localStorage.getItem('authToken') || ''
//   }
//   const currentPage = window.location.pathname
//   if (currentPage == '/my-farms') {
//     try {
//       const farmConfigApi = 'http://192.168.0.124:3666'
//       const myFarmList = (await (
//         await fetch(`${farmConfigApi}/farms/me`, {
//           headers: {
//             'Content-Type': 'application/json',
//             Authorization: `Bearer ${getAuthToken()}`,
//           },
//         })
//       ).json()) as SerializedFarmConfig[]
//       const myFarmPending = myFarmList.filter((item) => item.status === 'pending')
//       const modifiedFarmList = myFarmPending.map((farm, index) => ({
//         pid: index,
//         lpSymbol: farm.lpSymbol?.replace(' - ', '-') + ' LP',
//         lpAddress: farm.lpAddress || '',
//         token: farm.token
//           ? {
//               address: farm.token.address || '',
//               chainId: farm.token.chainId || 97,
//               decimals: Number(farm.token.decimals) || '',
//               symbol: farm.token.symbol || '',
//               name: (farm.token.name || '')?.replace(' Token', ''),
//               projectLink: 'https://craswap.com',
//             }
//           : {
//               address: '',
//               chainId: 97,
//               decimals: '',
//               symbol: '',
//               name: '',
//               projectLink: 'https://craswap.com',
//             },

//         quoteToken: farm.quoteToken
//           ? {
//               address: farm.quoteToken.address || '',
//               chainId: farm.quoteToken.chainId || 97,
//               decimals: Number(farm.quoteToken.decimals) || '',
//               symbol: farm.quoteToken.symbol || '',
//               name: farm.quoteToken.name || '',
//               projectLink: 'https://tether.to',
//             }
//           : {
//               address: '',
//               chainId: 97,
//               decimals: '',
//               symbol: '',
//               name: '',
//               projectLink: 'https://tether.to',
//             },
//       }))

//       return modifiedFarmList
//     } catch (error) {
//       if (!logged) {
//         console.error('Cannot get farm config', error, chainId)
//         logged = true
//       }
//       return []
//     }
//   }
// }
export const getFarmConfig = async (chainId: ChainId) => {
  try {
    return (await import(`/${chainId}.ts`)).default.filter(
      (f: SerializedFarmConfig) => f.pid !== null,
    ) as SerializedFarmConfig[]
  } catch (error) {
    if (!logged) {
      console.error('Cannot get farm config', error, chainId)
      logged = true
    }
    return []
  }
}

export const getStableConfig = async (chainId: ChainId) => {
  try {
    const farms = (await import(`/${chainId}.ts`)).default as SerializedFarmConfig[]

    return farms.filter(isStableFarm)
  } catch (error) {
    if (!logged) {
      console.error('Cannot get stable farm config', error, chainId)
      logged = true
    }
    return []
  }
}
